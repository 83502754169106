button {
  border: none;
  border: 1px solid #ebebeb;
  border-radius: 20px;
}

.secondary_btn {
  background-color: #ffffff;
  color: #000;
}

.primary_btn {
  background-color: #3e3dc6;
  color: #fff;
}

.final_btn {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.btn {
  width: 150px;
  height: 40px;
  cursor: pointer;
}

.primary {
  background-color: #3e3dc6;
  color: #fff;
}

.secondary {
  background-color: #ebebeb;
  color: #3e3dc6;
  border: 1px solid #3e3dc6;
}