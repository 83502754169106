.flex {
  display: flex;
  gap: 20px;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.card_container {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 30px;
  width: 880px;

  .btn_grp {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    justify-content: flex-end;
  }

  .card_heading {
    font-size: 18px;
  }

  .dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
    border: 2px dashed #ebebeb;
    border-radius: 20px;
    padding: 2rem;

    .dropzone,
    h1 {
      font-size: 14px;
      color: #000000;
    }

    .orText {
      color: #7a86a1;
    }

    .uploadIcon {
      margin-left: 5px;
    }

    span {
      display: block;
    }

    .uploadBtn {
      display: flex;
      align-items: center;
      gap: 10;
      width: 142px;
      height: 40px;
      background-color: white;
      border: none;
      border: 2px solid #ebebeb;
      border-radius: 20px;
    }
  }

  // Detail Section

  input,
  select {
    border-radius: 20px;
    border: none;
    border: 1px solid #ebebeb;
    padding: 15px;
  }

  select {}

  .category-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .form_controler {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    margin-bottom: 20px;
  }

  .card_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      color: #7a86a1;
      cursor: pointer;
    }
  }

  .name-section {
    width: 100%;
  }

  .flex_column {
    gap: 18px;
  }

  // input[type="checkbox"] {
  //   width: 20px;
  //   height: 20px;
  //   background-color: white;
  //   border-radius: 30%;
  //   vertical-align: middle;
  //   border: 1px solid #ddd;
  //   appearance: none;
  //   outline: none;
  //   cursor: pointer;

  // }

  // .checkbox:checked {
  //   background-color: #3e3dc6;
  // }

  .checkbox_section {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;

    label {
      font-size: 14px;
      padding-top: 5px;
      display: block;
    }
  }

  /* The container */
  .checkbox_section {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .checkbox_section input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 20px;
    width: 20px;
    left: 0;
    top: 0;
    margin: 0;
    z-index: 1;
    vertical-align: middle;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 32%;
    background-color: #fff;
    border: 1px solid #727272;
    margin-right: 20px;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked~.checkmark {
    background-color: #3e3dc6;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkbox_section input:checked~.checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox_section .checkmark:after {
    left: 7px;
    top: 3px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }



  // Media section

  .media_container {
    display: flex;
    gap: 20px;

    .media_drag {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}

.steps_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
}

.step_2 {
  justify-content: center;
}

.step_3 {
  justify-content: flex-end;
}

.line {
  flex: 1;
  margin: 0 20px;
  border-top: 1px dashed #E1E1E1;
}


.step {
  display: inline-block;
  padding-right: 10px;
  display: flex;
  align-items: center;
  gap: 10px;


  &.active {

    .line {
      background-color: #3E3DC6;
      flex: 1;
      height: 1px;
    }


    span {
      background-color: #3E3DC6;
      color: #fff;
      border-radius: 20px;
      width: 40px;
      height: 40px;
      border: 0;


    }

    p {
      color: #3E3DC6;
    }
  }
}


.step span {

  background: none;
  border: 2px dotted #ebebeb;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #7a86a1;
}

.step>p {
  color: #7a86a1;
}