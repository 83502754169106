body,
html {
 margin: 0;
 font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
 padding: 0;
}

code {
 font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
 text-decoration: none;
}
