.productDetail_container {
  background-color: #f1f1f1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 20px 20px 20px;
  gap: 50px;
  height: calc(100vh - 100px);

  .flex-item {
    flex-grow: 2;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.head-detail {
  background-color: #f1f1f1;
  padding: 20px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;

  .close-editor {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3E3DC6;
    font-size: 14px;

    .circle {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #3E3DC6;
      width: 32px;
      height: 32px;
      border-radius: 14px;
      margin: 0 10px;
      cursor: pointer;
    }

  }
}

.edit-text {
  color: #3E3DC6;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.mt-2 {
  margin-top: 15px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #3E3DC6;
}

input:focus+.slider {
  box-shadow: 0 0 1px #3E3DC6;
}

input:checked+.slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.featured {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.right_side,
.left_side {
  width: 100%;
}


.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: #000;
  height: 60px;
  box-shadow: 0 0 4px #e2e2e2;
  padding: 0 20px;
}

.navbar-brand {
  font-size: 1.5rem;
  margin: 0;
  cursor: pointer;
}

.navbar-nav {
  list-style: none;
  display: flex;
  margin-left: auto;
}

.nav-item {
  margin-right: 20px;
  cursor: pointer;
}

.nav-item a {
  text-decoration: none;
  color: white;
}

.slab-details-type {
  color: #fff;
  font-size: 12px;
  color: #000;
  font-weight: 600;
  min-width: 125px;
}

.thickness-col {
  display: flex;
  flex-direction: column;
  font-weight: normal;
}

.finishes-row {
  display: flex;
  gap: 10px;
  font-weight: normal;
}

.leftmost-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
}