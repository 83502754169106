/* Add this CSS to your stylesheets */
.toast-overlay {
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: rgba(255, 255, 255, 0.5);
  /* Semi-transparent background overlay */
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
  /* Adjust the z-index as needed */
}

.toast-content {
  background: #3E3DC6;
  border-radius: 20px;
  pointer-events: auto;
  width: auto;
  box-shadow: 0px 0px 80px #dcdcf6;
  /* Adjust the width as needed */
  padding: 5px 15px;
  font-size: 14px;
  text-align: left;
  color: #fff;
  margin-bottom: 20px;
  z-index: 1000;

  p {
    padding: 0;
    margin: 0;
  }
}

.loader-img {
  width: 100%;
  display: flex;
  justify-content: center;
}

.toast-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: normal;
}

.toast-overlay.red {

  .toast-content {
    box-shadow: 0px 0px 80px #fad6d6;
  }

  .toast-header,
  .toast-content,
  .description {
    background-color: #FF6262;
  }


  button.primary {
    background-color: #FF6262;
    color: #fff;
  }
}

.toast-header h2 {
  font-size: 18px;
  margin: 0;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.5rem;
  color: #777;
  border: 1px solid #EBEBEB;
  width: 20px;
  height: 20px;
  padding-top: 3.2px;
  border-radius: 15px !important;
  margin-left: 20px;
}

.toast-body {
  margin-top: 20px;
}

.toast-body p {
  font-size: 16px;
}

.toast-footer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 215px;
  justify-content: flex-end;
  display: flex;
  float: right;
}

.cancel-button,
.action-button {
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 16px !important;
  cursor: pointer;
}

.cancel-button {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #e2e2e2;
  color: #727272;
}

.action-button {
  background: #007bff;
  color: #fff;
}

.action-button:hover {
  background: #0056b3;
}

button {
  border: none;
  border: 1px solid #ebebeb;
  border-radius: 20px !important;
}

.secondary_btn {
  background-color: #ffffff;
  color: #000;
}

.primary_btn {
  background-color: #3e3dc6;
  color: #fff;
}

.final_btn {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.btn {
  width: 150px;
  height: 40px;
  cursor: pointer;
}

.primary {
  background-color: #3e3dc6;
  color: #fff;
}

.secondary {
  background-color: #ebebeb;
  color: #3e3dc6;
  border: 1px solid #3e3dc6;
}

.red-text {}

.red-body {}

.action-button {
  min-width: 90px !important;
}