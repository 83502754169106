/* Add this CSS to your stylesheets */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  /* Semi-transparent background overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Adjust the z-index as needed */
}

.modal-content {
  background: #fff;
  border-radius: 10px;
  width: 320px;
  box-shadow: 0px 0px 80px #dcdcf6;
  /* Adjust the width as needed */
  padding: 20px;
  font-size: 14px;
  text-align: left;

  p {
    padding: 0;
    margin: 0;
  }
}

.loader-img {
  width: 100%;
  display: flex;
  justify-content: center;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #3e3dc6;
}

.modal-overlay.red {

  .modal-content {
    box-shadow: 0px 0px 80px #fad6d6;
  }

  .modal-header,
  .description {
    color: #FF6262;
    margin-bottom: 5px;
  }


  button.primary {
    background-color: #FF6262;
    color: #fff;
  }
}

.modal-header h2 {
  font-size: 18px;
  margin: 0;
}

.close-button-modal {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  color: #777;
  border: 1px solid #EBEBEB;
  width: 32px;
  height: 32px;
  padding-top: 1px;
  border-radius: 15px !important;
}

.modal-body {
  margin-top: 20px;
}

.modal-body p {
  font-size: 16px;
}

.modal-footer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  justify-content: flex-end;
  display: flex;

  .btn {
    width: auto;
  }
}

.cancel-button,
.action-button {
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 16px !important;
  cursor: pointer;
}

.cancel-button {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #e2e2e2;
  color: #727272;
}

.action-button {
  background: #007bff;
  color: #fff;
}

.action-button:hover {
  background: #0056b3;
}

button {
  border: none;
  border: 1px solid #ebebeb;
  border-radius: 20px !important;
}

.secondary_btn {
  background-color: #ffffff;
  color: #000;
}

.primary_btn {
  background-color: #3e3dc6;
  color: #fff;
}

.final_btn {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.btn {
  width: 150px;
  height: 40px;
  cursor: pointer;
}

.primary {
  background-color: #3e3dc6;
  color: #fff;
}

.secondary {
  background-color: #ebebeb;
  color: #3e3dc6;
  border: 1px solid #3e3dc6;
}

.red-text {}

.red-body {}

.action-button {
  min-width: 90px !important;
}