.login-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;


  .left-section {
    flex: 1;
    background: url("../../public/Bg.png") center/cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    position: relative;
  }

  .left-section .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 0;
  }

  .right-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }

  .logo {
    font-size: 2rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    z-index: 1;
    position: relative;
  }

  .logo .welcome {
    font-size: 40px;
  }

  .logo .panel {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .logo .desc {
    font-size: 16px;
    font-weight: 300;
  }

  .login-form {
    width: 100%;
    max-width: 300px;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 3.5rem;
  }

  .input-group label {
    margin-bottom: 0.5rem;
    font-size: 12px;
    color: #60606A;
  }

  .input-group input {
    padding: 0.5rem;

    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #ccc;
    padding-left: 0;
  }

  .input-group input:focus {
    outline: none;
  }

  .submit-btn {
    background-color: #141414;
    color: white;
    padding: 1rem 2rem;
    border: none;
    border-radius: 12px;
    cursor: pointer;
  }

  .submit-btn:hover {
    background-color: #0056b3;
  }
}
