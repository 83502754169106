.image-uploader {
  max-width: 320px;
  height: 280px;
  padding: 20px;
  border-radius: 20px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 240px;
}

.drop-area {
  width: 100%;
  height: 100%;
  border: 1px dashed #EBEBEB;
  display: flex;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  position: relative;
  padding: 10px;
}

.uploaded-image {
  max-width: 100%;
  max-height: 100%;
  width: 47%;
  margin: 2px;
  height: 50%;
}

.upload-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0);
  color: #7A86A1;
  border: 1px solid #EBEBEB;
  border-radius: 20px;
  // border-radius: 5px;
  cursor: pointer;
  display: flex;
  gap: 10px;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.or {
  color: #7A86A1;
  font-size: 14px;
  margin: 10px;
}

.stone {
  margin: 10px 0 20px 0;
  text-align: left;
  width: 100%;
}

.cross {
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
  color: #7A86A1;
  font-size: 10px;
  border: 1px solid #EBEBEB;
  border-radius: 15px;
  padding: 5px 10px;
}

.preview {
  padding: 10px;
  height: 100px;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}