.dashboard-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;


  .slick-prev {
    left: 0;
    z-index: 1;
  }

  .slick-next {
    right: 0 !important;
    z-index: 1;
  }

  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.75;
  }

  ::-webkit-scrollbar {
    width: 3.2px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #E2E2E2;
    border-radius: 2px;
    margin-right: -10px;
    position: absolute;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #6149CD;
    border-radius: 2px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #4b33b9;
  }

  .dashboard-content {
    flex: 1;
    background-color: #f4f4f4;
    padding: 0;
  }

  .flex-container {
    display: flex;
    height: 100%;
  }

  .left-section {
    width: 520px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px);
    // padding: 10px;

    .dashboard-card {
      background-color: #000;
      padding: 20px;
      color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .slab-card {
      background-color: #d4d4d4;
      padding: 15px 20px;
      color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      position: relative;
      // margin-top: 4px;

      .greeting {
        color: #000;
        font-weight: bold;
      }
    }

    .slab_details {
      display: flex;
      width: 100%;
      position: relative;

      .slab_image {
        width: 100%;
      }
    }

    .greeting {
      font-size: 18px;

      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .list-product-button {
      background-color: #a9d8ff;
      color: #000;
      padding: 10px 20px;
      border: none;
      border-radius: 12px !important;
      cursor: pointer;
      margin-right: 10px;
    }

    .list-product-button:hover {
      background-color: #0056b3;
    }

    .details {
      display: flex;
      gap: 16px;
    }

    .label {
      font-size: 12px;
      margin-top: 10px;

      span {
        font-size: 24px;
        color: #a9d8ff;
      }
    }
  }

  .right-section {
    flex: calc(100% - 520px);
    background-color: #eee;
    // padding: 10px;

    .search-container {
      margin-top: 40px;
      padding: 20px;
      background-color: #a9d8ff;
      color: #fff;
      display: flex;
      justify-content: space-between;
      gap: 20px;

      input {
        border: 1px solid #fff;
        background-color: rgba(0, 0, 0, 0);
        border-radius: 8px;
        padding: 15px;
        color: #3e3dc6;
        width: 100%;

        &::placeholder {
          color: #3e3dc6;
        }

        &:focus {
          outline: none;
        }
      }

      .stone_image {
        width: 60px;
        height: 60px;
      }

      .search-btn {
        background-color: #3e3dc6;
        color: #fff;
        width: 120px;
        border-radius: 12px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        cursor: pointer;
      }
    }
  }

  /* Add this CSS to your styles.css file */
  .navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: #000;
    height: 60px;
    box-shadow: 0 0 4px #e2e2e2;
    padding: 0 20px;
  }

  .navbar-brand {
    font-size: 1.5rem;
    margin: 0;
  }

  .navbar-nav {
    list-style: none;
    display: flex;
    margin-left: auto;
  }

  .nav-item {
    margin-right: 20px;
  }

  .nav-item a {
    text-decoration: none;
    color: white;
  }

  .feature-dashboard-card {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-self: stretch;
    flex: 1;
    overflow-y: auto;
    margin-top: 20px;
    padding: 0 40px;
    min-height: 0;
    margin: 0 20px;

    /* Add this style to your existing _dashboard.scss */

    .vertical-item-list {
      display: flex;
      flex-direction: column;
    }

    .vertical-item {
      display: flex;
      align-items: center;
      margin: 15px 0;
    }

    .vertical-item-image {
      width: 50px;
      height: 50px;
      background-color: #f4f4f4;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      font-weight: bold;
      font-size: 18px;
      margin-right: 16px;

      img {
        width: 100%;
      }
    }

    .vertical-item-info {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .vertical-item-name {
      font-size: 16px;
      margin-bottom: 5px;
    }

    .vertical-item-description {
      color: #666;
      font-size: 12px;
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
    }

    .vertical-item-date {
      color: #999;
      font-size: 14px;
    }
  }

  .feature-title {
    font-size: 14px;
    padding: 40px 40px 0 40px;
    margin: 0 20px;
    margin-bottom: 10px;
  }

  .feature-gallery {
    width: calc(100vw - 480px);
    margin-top: 20px;
    min-height: 182px;

    .feature-card {
      margin: 0;
      white-space: nowrap;
      text-align: center;
      overflow: auto;
      border-radius: 15px;
      overflow: hidden;
      transition: box-shadow 0.2s ease;

      &.active {
        box-shadow: 0 4px 12px #9d9d9d;
        outline: 1px solid #fff;
        box-sizing: content-box;
      }

      &:hover {
        // box-shadow: 0 4px 12px #9d9d9d;
        outline: 1px solid #fff;
      }
    }

    .feature-card-content {
      color: #fff;
      width: 100%;
      height: 120px;
      // border-radius: 15px;
      background-color: #f8f8f8;
      padding: 20px;
      display: flex;
      position: relative;
      cursor: pointer;



      .category-image {
        align-items: flex-start;
        position: absolute;
        right: 50px;
      }

      .category-detail {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        height: 100%;
        gap: 10px;

        .count {
          font-size: 14px;
        }
      }
    }

    .MARBLE {
      background-image: linear-gradient(to left, #702068, #cf93c9);
    }

    .GRANITES {
      background-image: linear-gradient(to left, #185d51, #6bc9b9);
    }

    .PORCELAIN {
      background-image: linear-gradient(to left, #143355, #274f7e);
    }

    .QUARTZITE {
      background-image: linear-gradient(to left, #702068, #cf93c9);
    }

    .QUARTZ {
      background-image: linear-gradient(to left, #512b13, #653f27);
    }

    .SEMIPRECIOUS,
    .SEMI {
      background-image: linear-gradient(to left, #512b13, #653f27);
    }
  }

  .slick-slide>div {
    margin: 10px;
  }

  .second-vertical-section {
    margin-top: 20px;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .listings {
    display: flex;
    overflow: auto;
    flex-direction: column;

    background-color: #fff;
    padding: 40px 40px 20px 40px;
    height: calc(100vh - 450px);
    background-color: #fff;

    .listing-item {
      display: flex;
      // width: 100%;
      flex-direction: row;
      justify-content: space-between;
      padding: 5px 20px;
      align-items: center;
      transition: all 0.2s ease-in-out;
      border-radius: 22px;
      cursor: pointer;

      &:hover {
        box-shadow: 4px 4px 12px #695f972d;
      }
    }

    .listing-title {
      font-size: 12px;
      margin-left: 10px;
      margin-bottom: 10px;
    }

    .listing-action {
      position: relative;
    }

    .listing-intro {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 200px;

      .listing-name {
        display: flex;
        flex-direction: column;

        span {
          font-size: 12px;
        }
      }
    }
  }

  .slick-dots {
    li {
      button {
        &:before {
          color: #000; // Adjust color as needed
        }
      }
    }
  }

  .action-drowpdown {
    box-shadow: 0 0 4px #0056b3;
    display: flex;
    flex-direction: column;
    position: absolute;
    padding: 10px;
    width: 100px;
    right: 1px;
    border-radius: 16px;
  }
}


.collapse {
  position: absolute;
  bottom: 0px;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  cursor: pointer;
  display: inline-block;

  .collapsedTrue {
    transform: rotateZ(180deg);
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 4px 4px 12px #695f972d;
  padding: 16px 16px;
  z-index: 1;
  right: 0;
  border-radius: 18px;
}

.dropdown-title {
  margin-bottom: 8px;
  font-size: 12px;
  color: #7A86A1;
}

.dropdown-list {
  color: #7B7B7F;
  padding: 10px;
  margin: 6px 0;
  font-size: 14px;
  border-radius: 20px;

  a {
    text-decoration: none;
    color: inherit;
  }

  &:hover {
    color: #000000;
    background-color: #A9D8FF;

  }
}

.dropdown:hover .dropdown-content {
  display: block;
}

.loader {
  display: flex;
  justify-content: center;
  // width: 100%;
  padding: 40px;
}